import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item', 'radio', 'submit']

  select(e) {
    const value = e.target.value
    this.itemTargets.forEach(item => {
      item.classList.toggle('selected', item.dataset.id == value)
    })
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = false
    }
  }
}